<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="Array.isArray(detail.certList) ? detail.certList : []"
      :pagination="false"
    >
      <a-table-column title="证书类型" align="center">
        <template slot-scope="text">
          <span v-if="text.type =='registeredCert'">注册证书</span>
          <span v-if="text.type =='professionalCert'">职称证书</span>
          <span v-if="text.type =='other'">其他</span>
        </template>
      </a-table-column>
      <a-table-column title="证书名称" align="center">
        <template slot-scope="text">
          <DataDictFinder
            v-if="text.type =='registeredCert'"
            dictType="talentMap.registeredProfessionalQualifications"
            :dictValue="text.name"
          />
          <DataDictFinder
            v-if="text.type =='professionalCert'"
            dictType="talentMap.certificate.professionalCert"
            :dictValue="text.name"
          />
          <span v-if="text.type =='other'">{{ text.name }}</span>
        </template>
      </a-table-column>

      <a-table-column title="证书编号">
        <template slot-scope="text">
          {{ text.number }}
        </template>
      </a-table-column>

      <a-table-column title="发证日期" align="center">
        <template slot-scope="text">
          {{ text.issueDate }}
        </template>
      </a-table-column>
      <a-table-column title="过期日期" align="center">
        <template slot-scope="text">
          {{ text.expireDate }}
        </template>
      </a-table-column>
      <a-table-column title="附件" align="center">
        <template slot-scope="text">
          <a v-if="text.attachment" target="_blank" :href="text.attachment"
            >预览</a
          >
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>